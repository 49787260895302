/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2025-02-13 17:04:59
 */
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { ReactComponent as CloseButton } from "../../../icons/untitled-ui/closeXButton.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import { createTypography } from "src/theme/base/customTypography";
import { saveAs } from '../../../index'
import Button from '@mui/material/Button';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import IosShareIcon from '@mui/icons-material/IosShare';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
// import toast from "react-hot-toast";
import { toast } from 'react-toastify';
import { useDownload } from "src/contexts/downloadContext";

export const DownloadPagePopUp = (props) => {
  const typography = createTypography();
  const surveyId = props.surveyId;
  const fileName = props.fileName;
  const isGp = props.isGp;
  const { onClose, open = false, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');
  const { setStateContext } = useDownload();
  const handleClose = () => {
    onClose()
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(link.sharedLink);
    toast.success('Link copied');
  };

  const handleMouseDownCopy = (event) => {
    event.preventDefault();
  };

  const getShareLink = async (type) => {

    try {
      setStateContext(true);
      setIsLoading(true);
      if (type === "pdf") {

        onClose();
      }
      let json;
      const response = await saveAs(
        surveyId,
        type,
        fileName,
        json,
        undefined,
        isGp
      )
      setLink(response === false ? null : response);
      setIsLoading(false);
      setStateContext(false);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Dialog fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      {...other}>

      <DialogTitle
        justifyContent="space-between"
        alignItems="center">
        <Typography sx={{ ...typography.cardHeader.regular, fontSize: 24 }}>
          {fileName && fileName}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseButton />
      </IconButton>
      <Divider sx={{ backgroundColor: '#000000' }} />
      {(isLoading) &&
        (<>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
        )}
      <DialogContent>
        <Stack p={0}
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          flex={1}
        >
          <Button
            color="primary"
            size="medium"
            variant="text"
            onClick={() => getShareLink("pdf")}
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              paddingLeft: "12px",
            }}
            startIcon={<SimCardDownloadIcon />}
          >
            Download as PDF
          </Button>
          <Button
            color="primary"
            size="medium"
            variant="text"
            onClick={() => getShareLink("html")}
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              paddingLeft: "12px",
            }}
            startIcon={<IosShareIcon />}
          >Share as link</Button>
          {link &&
            <FormControl sx={{ m: 1, width: "100%", }}
              variant="outlined">
              <OutlinedInput
                id="standard-adornment-password"
                type='text'
                disabled
                defaultValue={link && link.sharedLink}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleCopyToClipboard}
                      onMouseDown={handleMouseDownCopy}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          }
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
