/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:41:02
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 21:46:23
 */
import axios from "axios";
import { Url } from "src/config";

const teamDashboardResultsApi = {
  /**
   * Fetches the Mojo Meter result for a specific user and team, optionally filtered by date.
   *
   * @async
   * @function getMyMojoMeterResult
   * @param {string} accessToken - The access token for authentication.
   * @param {string} id - The ID of the user.
   * @param {string} teamId - The ID of the team.
   * @param {string} [date] - (Optional) The date to filter the results.
   * @returns {Promise<Object|Error>} A promise resolving to the Mojo Meter result data or an error object.
   *
   * @example
   * const result = await getMyMojoMeterResult("your-access-token", "123", "456", "2025-01-01");
   * console.log("Mojo Meter Result:", result);
   *
   * @throws Logs the error and optionally rethrows it for further handling.
   */
  getMyMojoMeterResult: async (accessToken, id, teamId, date) => {
    try {
        const params = {
            id: id,
            teamId: teamId,
        };

        if (date) {
            params.date = date;
        }

        const response = await axios.get(
            `${Url}/customer/getMyMojoTeamMeterResult`,
            {
                headers: {
                    "access-token": accessToken,
                },
                params: params,
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
},
  /**
   * Fetches team diversity results for a specific user and team.
   *
   * @async
   * @function getTeamDiversityResults
   * @param {string} accessToken - The access token for authentication.
   * @param {string} id - The ID of the user.
   * @param {string} teamId - The ID of the team.
   * @returns {Promise<Object|Error>} A promise resolving to the team diversity results or an error object.
   *
   * @example
   * const result = await getTeamDiversityResults("your-access-token", "123", "456");
   * console.log("Team Diversity Results:", result);
   *
   * @throws Logs the error and optionally rethrows it for further handling.
   */
  getTeamDiversityResults: async (accessToken, id, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getTeamDiversityResults`,{
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id,
          teamId: teamId,
        },
      });
      return response.data;
    } catch (error) {
      return error
    }
  },
  /**
   * Fetches organizational results for a specific user based on the Click With feature.
   *
   * @async
   * @function getClickWithOrgResults
   * @param {string} accessToken - The access token for authentication.
   * @param {string} id - The ID of the user.
   * @returns {Promise<Object|void>} A promise resolving to the organizational results or `undefined` if an error occurs.
   *
   * @example
   * const results = await getClickWithOrgResults("your-access-token", "user-id");
   * console.log("Organization Results:", results);
   *
   * @throws Logs the error if fetching results fails.
   */
  getClickWithOrgResults: async (accessToken, id) => {
    try {
      const response = await axios.get(`${Url}/customer/getClickWithOrgResults`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Fetches the teams associated with a specific user.
   *
   * @async
   * @function getUsersTeams
   * @param {string} accessToken - The access token for authentication.
   * @param {string} id - The ID of the user whose teams are being fetched.
   * @returns {Promise<Object|Error>} A promise resolving to the user's teams data or an error object.
   *
   * @example
   * const teams = await getUsersTeams("your-access-token", "user-id");
   * console.log("User's Teams:", teams);
   *
   * @throws Logs the error and returns it if fetching the teams fails.
   */
  getUsersTeams: async (accessToken, id) => {
    try {
      const response = await axios.get(`${Url}/customer/getUsersTeams`,{
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id
        },
      });
      return response.data;
    } catch (error) {
      return error
    }
  },
  /**
   * Fetches team compatibility results for a specific user and team.
   *
   * @async
   * @function getTeamCompatabilityResults
   * @param {string} accessToken - The access token for authentication.
   * @param {string} id - The ID of the user.
   * @param {string} teamId - The ID of the team.
   * @returns {Promise<Object|Error>} A promise resolving to the team compatibility results or an error object.
   *
   * @example
   * const results = await getTeamCompatabilityResults("your-access-token", "user-id", "team-id");
   * console.log("Team Compatibility Results:", results);
   *
   * @throws Logs the error and returns it if the request fails.
   */
  getTeamCompatabilityResults: async (accessToken, id, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getTeamCompatabilityResults`,{
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id,
          teamId: teamId,
        },
      });
      return response.data;
    } catch (error) {
      return error
    }
  },
  /**
   * Checks if a user is the team leader of a specific team.
   *
   * @async
   * @function checkTeamLeader
   * @param {string} teamId - The ID of the team to check.
   * @param {string} id - The ID of the user.
   * @param {string} accessToken - The access token for authentication.
   * @returns {Promise<Object|Error>} A promise resolving to the check result or an error object.
   *
   * @example
   * const result = await checkTeamLeader("team-id", "user-id", "your-access-token");
   * console.log("Is Team Leader:", result.isLeader);
   *
   * @throws Logs the error and returns it if the request fails.
   */
  checkTeamLeader: async (teamId, id, accessToken) => {
    try {
      const response = await axios.get(`${Url}/customer/CheckingTeamLeader`, {
        headers: {
          "access-token": accessToken
        },
        params: {
          id: id,
          teamId: teamId,
        }
      });
      return response.data;
    } catch (error) {
      return error
    }
  }

}

export default teamDashboardResultsApi;