/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 16:43:47
 */
import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
// import { Backdrop, CircularProgress} from "@mui/material";
import { ReactComponent as SmartLeaderLogo } from "../../icons/untitled-ui/smartLeaderWhite.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import SurveyTile from "./surveys/surveyCards";
// import LeadershipBrandSurveyTile from "./leadershipBrandComponents/surveyCards";
import { RouterLink } from "src/components/router-link";
// import surveyAPIService from "src/services/surveyApi";
import surveyAPIService from "src/services/surveyApi";
import { useUser } from "src/hooks/use-user";
import { useState } from "react";
import { createTypography } from "src/theme/base/customTypography";
import loginApiService from "src/services/loginAPI";
import { useLocation } from "react-router";
import MyLeadershipSurveyTile from "./components/surveyCards";
// import { createTypography } from "../../theme/base/customTypography";
import * as SldButtons from "src/theme/base/SldButtons";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import myLeadershipApi from "src/services/myLeadershipApi";
import { useNavigate } from 'react-router-dom';
import LinkExpired from "../wiredUpSurveys/surveys/linkExpire";
import CryptoJS from "crypto-js";


function MyLeadershipSurveyStartPage(props) {
  const { children } = props;
  // const id = 7;
  // eslint-disable-next-line
  const token = "tokenuser";
  // const sectionPage = 1;

  const accessToken = sessionStorage.getItem("accessToken");
  const user = useUser();
  const queryParameters = new URLSearchParams(window.location.search);
  // const surveyId = queryParameters.get("surveyId");
  // const queueId = queryParameters.get("queueId");
  const location = useLocation();

  // const surveyId = 5;
  const [inviteId, setInviteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [emailToken, setEmailToken] = useState(null)
  const [emailInviteId, setEmailInviteId] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  // const [teamId,setTeamId] = useState(null);
  const [queueId, setQueueId] = useState(null);
  const [surveyId, setSurveyId] = useState(queryParameters.get("surveyId"));
  const [invitedUserName, setInvitedUserName] = useState()
  const [hasUsersInvited, setHasUsersInvited] = useState(false)
  const [invitedUserId, setInvitedUserId] = useState(queryParameters.get("invitedUserId"));
  const navigate = useNavigate()
  const [valid, setValid] = useState(0);
  const [isBoss, setIsBoss] = useState(null);
  const [fromLink, setFromLink] = useState(false)
  const [selfCompleted, setSelfCompleted] = useState(false)
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  console.log('surveyPerms',surveyPermsEn);
  let selfOnly063=false
if(surveyPermsEn){

  let surveyPerms = CryptoJS.AES.decrypt(
    surveyPermsEn,
    user?.id?.toString()
  ).toString(CryptoJS.enc.Utf8);
  selfOnly063 = JSON.parse(surveyPerms).includes(20)
}
else{
  selfOnly063=false
}
  let surveyLinkParameters = '';
 


  /**
   * Function to get invite id , If no invite id is present new invite id will be created
   * @date 11/5/2023 - 12:07:16 AM
   *
   * @async
   * */
  const getInviteId = async () => {
    try {
      const inviteIdGetData = {
        survey_id: surveyId,
        inviteId: inviteId,
        timeStamp: "",
        teamId: "",
      };
      //api call to check invite id
      const response = await surveyAPIService.checkInviteId(
        inviteIdGetData,
        accessToken
      );
      if (response.data.inviteId) {
        setInviteId(response.data.inviteId);

      }

      setIsLoading(false);
    } catch (error) {

    }
  };

  const validateToken = async () => {
    try {
      setFromLink(false)
      const response = await loginApiService.decodeToken(emailToken);
      if (response?.userId || response.userEmail) {
        const stringifiedUser = JSON.stringify(response)
        sessionStorage.setItem("userDetails", stringifiedUser);
        sessionStorage.setItem("accessToken", emailToken)
        setInviteId(emailInviteId);
        setFromLink(true)
      }


    } catch (error) {

    }
  }
  useEffect(() => {
    if (location.search.substring(1) && !(queryParameters.get("surveyId"))) {
      const base64String = location.search.substring(1);

      // Decode the base64 string to get the original string
      const decodedString = atob(base64String);

      // Split the decoded string to get individual parameters
      const paramsArray = decodedString.split('&');

      // Create an object to store the parameters
      const params = {};

      // Populate the params object
      paramsArray.forEach(param => {
        const equalSignIndex = param.indexOf('=');
        const key = param.slice(0, equalSignIndex);
        const value = param.slice(equalSignIndex + 1);
        params[key] = value;
      });
      // Set your state variables accordingly
      setEmailInviteId(params.inviteId);
      setEmailToken(params.token);
      setSurveyId(params.surveyId);
      setQueueId(params.queueId);
      // setTeamId(params.teamId);
      setInvitedUserId(params.invitedUserId)
      setInvitedUserName(params.invitedUser)
      setTenantId(params.tenantId);
      setIsBoss(params.isBoss)

    }
    else {
      setInvitedUserName(user.firstName)
      getInviteId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])
  const typography = createTypography();
  const validateLink = async () => {
    try {
      const response = await loginApiService.validateTeamLink(emailToken, queueId, tenantId, inviteId, user.id);
      if (response.length > 0) {
        if (response[0].active === 0 || response[0].is_completed === 1 || response[0].is_deleted === 1) {
          setValid(1);
        }
        else {
          navigateToQuestion()

        }

      }
      else {
      }

      setIsLoading(false)
    } catch (error) {

    }
  }
  useEffect(() => {
    if (inviteId && emailInviteId && emailToken && queueId) {
      validateLink();
    }
    // eslint-disable-next-line
  }, [inviteId, emailInviteId, emailToken])
  useEffect(() => {

    if (emailInviteId && emailToken && queueId) {
      validateToken()
    }
    // else if(accessToken && emailToken === null && emailInviteId === null && queueId=== null &&  fromLink){
    //  getInviteId()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailInviteId, emailToken, queueId])

  // useEffect(() =>{

  // })
  const checkStatus = async () => {
    const response = await myLeadershipApi.getMyLeadershStatus(accessToken)
    setHasUsersInvited(response.resData.resData.hasInvited)
    setSelfCompleted(response.resData.resData.selfCompleted)
  }
  useEffect(() => {
    if (accessToken && surveyId && emailInviteId === null && emailToken === null) {
      checkStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, emailInviteId, emailToken, surveyId])

  if (invitedUserId && queueId) {
    surveyLinkParameters = `&queueId=${queueId}`
    if (tenantId) {
      surveyLinkParameters = surveyLinkParameters + `&tenantId=${tenantId}`
    }
  }

  useEffect(() => {
    if (selfCompleted && hasUsersInvited) {

      setValid(1)
    }
  }, [hasUsersInvited, selfCompleted])
  /**
   * Function to navigate to survey questions page
   */
  const navigateToQuestion = () => {

    surveyLinkParameters = `&queueId=${queueId}`
    if (tenantId) {
      surveyLinkParameters = surveyLinkParameters + `&tenantId=${tenantId}`

      if (isBoss) {
        surveyLinkParameters = surveyLinkParameters + `&isBoss=1`
      }

      navigate(`/surveyQuestions?id=${surveyId}&inviteId=${inviteId}&invitedUserId=${invitedUserId}&invitedUser=${invitedUserName}${surveyLinkParameters}`)

    }
  }
  // useEffect(() => {
  //   if(invitedUserId && queueId&&fromLink &&valid===0){
  //   navigateToQuestion()
  // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[invitedUserId,queueId,fromLink,isBoss,valid])

  // useEffect(()=>{
  if (valid === 1) {
    return <LinkExpired />;
  }
  // },[valid])
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }



  return (
    <SldSurveyCard.SurveyGrid
      container
      spacing={0}

    >
      <SldSurveyCard.SurveyGridItem
        item
        lg={12}
        sm={12}
      >
        <SldSurveyCard.SurveyLogo>
          <SmartLeaderLogo width='250' />
        </SldSurveyCard.SurveyLogo>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          spacing={6}
        >
          <Typography
            sx={typography.qNheading}

          >
            My Leadership Survey Questionnaire
          </Typography>
          <SldButtons.SurveyClock
            variant="contained">
            <AccessTimeIcon />
            <Typography variant="subtitle1"> Duration <span>30mins</span></Typography>
          </SldButtons.SurveyClock>
          {/* </Grid> */}


        </Stack>
      </SldSurveyCard.SurveyGridItem>
      <SldSurveyCard.SurveyStack
        spacing={3}

        sx={{
          marginTop: { lg: '-140px', sm: '5px', xs: '3px' },
          padding: { lg: '0 60px', md: '0 60px', sm: '0 0', xs: '0' },
        }}
      >
        <MyLeadershipSurveyTile />
        <SldSurveyCard.SurveyRules
          lg={12}
        >
          <SldSurveyCard.SurveyContainer>{children}</SldSurveyCard.SurveyContainer>
          {/* </SurveyStack> */}

          {(hasUsersInvited|| selfOnly063) && <SldButtons.WhiteButtonCap endIcon={<ArrowForwardIcon />}
            variant="contained"
            component={RouterLink}
            to={`/surveyQuestions?id=${surveyId}&inviteId=${inviteId}&invitedUserId=${invitedUserId}&invitedUser=${invitedUserName}${surveyLinkParameters}`}
          >
            <Typography sx={typography.startButton}>Start</Typography>
          </SldButtons.WhiteButtonCap>}
          {!hasUsersInvited && !fromLink && !selfOnly063&& <SldButtons.WhiteButtonCap endIcon={<ArrowForwardIcon />}
            variant="contained"
            component={RouterLink}
            to={`/MyLeadershipUserInvite?surveyId=${surveyId}&invitedUserId=${invitedUserId}`}  >
            <Typography sx={typography.startButton}>
              SEND INVITATIONS TO COLLEAGUES TO BEGIN YOUR 063° FEEDBACK PROCESS
            </Typography>
          </SldButtons.WhiteButtonCap>}

        </SldSurveyCard.SurveyRules >
      </SldSurveyCard.SurveyStack>
    </SldSurveyCard.SurveyGrid>
  );
}

export default MyLeadershipSurveyStartPage;
